'use strict';

export const button = (() => {

    const init = () => {
        let $clickCount = 0;
        $('.js-toggle-button').on('click', event => {
            $clickCount ++;
            if ( $clickCount % 2 === 1 ){
                $('.common-share').toggleClass('is-active').removeClass('is-hide');
            } else {
                $('.common-share').toggleClass('is-hide').removeClass('is-active');
            }
        });
    };

    return {
        init: init
    };
})();
