'use strict';

import slick from 'slick-carousel';

export const carousel = (() => {
    const w = parseInt($('body').width());

    /**
     * init
     */
    const init = () => {
        if($(window).width() > 768) {
            $('.js-carousel-movie').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                swipe: true,
                centerMode: true,
                centerPadding: '23.7%'
            });
        } else {
            $('.js-carousel-movie').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                swipe: true,
                centerMode: true,
                centerPadding: '6.3%'
            });
        }

        $('.js-carousel-scene').slick({
            autoplay: true,
            autoplaySpeed: 0,
            speed: 30000,
            cssEase: 'linear',
            swipe: false,
            arrows: false,
            pauseOnFocus: false,
            pauseOnHover: false,
            // variableWidth: true,
        });
    }

    // public
    return {
        init: init
    };
})();