'use strict';

import TweenLite from 'gsap/TweenLite';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
const scrollPlugin = ScrollToPlugin; //webpackのproduction対策

export const anchor = (() => {

    /**
     * init
     */
    const init = () => {
        // #を含むアンカーの場合
        $('a[href*="#"]').on('click', event => {
            const id = $(event.currentTarget).attr('href');
            const href = $(event.currentTarget).prop('href');

            if (compareCurrentDir(href)) {
                // ターゲットidが現在のページ内を指定していたら
                moveTo(event, id);
            }
        });

        // #から始まるアンカーの場合
        $('a[href^="#"]').on('click', event => {
            const $this = $(event.currentTarget);
            moveTo(event, $this.attr('href'));
        });
    };

    /**
     * moveTo
     */
    const moveTo = (event, id, speed, callback) => {
        const $target = $(id.substr(id.indexOf('#')));
        let duration = speed === undefined ? 1.2 : speed;
        let offset = $('.layout-header').length ? $('.layout-header').outerHeight() : 0;

        if(event !== null) {
            event.preventDefault();
        }

        TweenLite.to(window, duration, {
            scrollTo: {
                y: $target.offset().top - offset,
                autoKill: false // この設定をしないとiPhoneで途中で止まる
            },
            ease: Power4.easeOut,
            onComplete: () => {
                if(callback) {
                    callback();
                }
            }
        });
    };

    /**
     * compareCurrentDir
     */
    const compareCurrentDir = href => {
        const path = href.substring(0, href.lastIndexOf('#'));
        let location = window.location.href;

        if (location.lastIndexOf('#') >= 0) {
            location = location.substring(0, location.lastIndexOf('#'));
        }

        if (path === location) {
            return true;
        } else {
            return false;
        }
    };

    // public
    return {
        init: init,
        moveTo: moveTo
    };
})();
