'use strict';

export const nav = (() => {

    const init = () => {
        $('.js-toggle-nav').on('click', event => {
            toggle();
        });

        $('.js-hide-nav').on('click', event => {
            hide();
        });
    };

    const toggle = () => {
        $('body').toggleClass('is-show-nav');
    };

    const hide = () => {
        $('body').removeClass('is-show-nav');
    };

    return {
        init: init,
        hide: hide
    };
})();
