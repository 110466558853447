'use strict';

export const modal = (() => {

    /**
     * init
     */
    const init = () => {
        if($(window).width() < 768) {
            $('body').addClass('is-close-modal');
        } else {
            $('.js-modal').addClass('is-active');
            $('.js-modal-movie').eq(0).addClass('is-active');
            $('.js-modal-button').eq(0).addClass('is-active');
        }

        $('.js-modal-thumbnail').on('click', function(event) {
            var $modalIndex = $(this).data('modal-index');
            var $modalId = $('#iframe0' + $modalIndex);

            $('.js-modal').addClass('is-active');
            $('.js-modal-movie').eq($modalIndex).addClass('is-active');
            $('.js-modal-button').eq($modalIndex).addClass('is-active');
            play("playVideo", $modalId);
        });

        $('.js-modal-button').on('click', function() {
            var $buttonIndex = $(this).index();
            var $activeIndex = $('.js-modal-movie.is-active').index();

            if ($buttonIndex !== $activeIndex){
                var $buttonId= $('#iframe0' + $buttonIndex);
                var $activeId = $('#iframe0' + $activeIndex);

                $activeId.attr('src', $activeId.attr('src').replace('&autoplay=1', ''));
                $('.js-modal-movie').removeClass('is-active');
                $('.js-modal-button').removeClass('is-active');
                $('.js-modal-movie').eq($buttonIndex).addClass('is-active');
                $('.js-modal-button').eq($buttonIndex).addClass('is-active');
                play("playVideo", $buttonId);
            }
        });

        $('.js-modal-close').on('click', function() {
            hide();
        });

        $(document).on('keydown', function(event) {
            if (event.key === 'Escape' || event.key === 'Esc') {
                hide();
            }
        });



        $(document).on('click', '.js-show-modal', event => {
            event.preventDefault();

            const $this = $(event.currentTarget);
            const id = $this.data('modal-id');
            const $modal = $('#' + id);

            show($modal);
        });

        $(document).on('click', '.js-close-modal', event => {
            const $this = $(event.currentTarget);
            const id = $this.data('modal-id');
            const $modal = $('#' + id);

            close($modal);
        });
    };

    /**
     * play
     */
    function play(action,tgt){
        var $playerWindow = $(tgt)[0].contentWindow;
        $playerWindow.postMessage('{"event":"command","func":"'+action+'","args":""}', '*');
    }

    /**
     * hide
     */
    const hide = () => {
        var $activeIndex = $('.js-modal-movie.is-active').index();
        var $activeId = $('#iframe0' + $activeIndex);
        $activeId.attr('src', $activeId.attr('src').replace('&autoplay=1', ''));
        $('.js-modal').removeClass('is-active');
        $('.js-modal-movie').removeClass('is-active');
        $('.js-modal-button').removeClass('is-active');
        $('body').addClass('is-close-modal');
    };

    /**
     * show
     */
    const show = ($modal) => {
        $modal.addClass('is-active');
        $modal.find('.modal__overlay').height($modal.find('.modal__body').height());
        $('body').addClass('is-show-modal');
    };

    /**
     * close
     */
    const close = $modal => {
        $modal.removeClass('is-active');
        $('body').removeClass('is-show-modal');
    };

    return {
        init: init
    };
})();
