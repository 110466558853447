'use strict';

import 'waypoints/lib/jquery.waypoints.min';

export const inview = (() => {

    /**
     * init
     */
    const init = () => {
        $('.js-inview').each((index, element) => {
            const waypoint = new Waypoint({
                element: element,
                handler: direction => {
                    $(element).addClass('is-inview');
                },
                offset: $(element).data('offset') ? $(element).data('offset') : '90%'
            });
        });

        if($('.js-inview-history').length) {
            $('.js-inview-history').each((index, element) => {
                new Waypoint({
                    element: element,
                    handler: direction => {
                        if(direction === 'down') {
                            const id = $(element).attr('id');
                            $('.nav-history').find('.is-active').removeClass('is-active')
                            $('.nav-history').find(`a[href="#${id}"]`).addClass('is-active');
                        }
                    },
                    offset: ($('.layout-header').outerHeight() + 10) + 'px'
                });
                
                new Waypoint({
                    element: element,
                    handler: direction => {
                        if(direction === 'up') {
                            const id = $(element).attr('id');
                            $('.nav-history').find('.is-active').removeClass('is-active')
                            $('.nav-history').find(`a[href="#${id}"]`).addClass('is-active');
                        }
                    },
                    offset: ($('.layout-header').outerHeight() - 10) + 'px'
                });
    
                // new Waypoint({
                //     element: element,
                //     handler: direction => {
                //         if(direction === 'down') {
                //             const id = $(element).attr('id');
                            
                //             if(id === 'y2020') {
                //                 $('.nav-history').addClass('is-hide');
                //             }
                //         }
                //     },
                //     offset: ($('.layout-header').outerHeight() - $(element).outerHeight()) + 'px'
                // });
                
                // new Waypoint({
                //     element: element,
                //     handler: direction => {
                //         if(direction === 'up') {
                //             const id = $(element).attr('id');
                            
                //             if(id === 'y2020') {
                //                 $('.nav-history').removeClass('is-hide');
                //             }
                //         }
                //     },
                //     offset: ($('.layout-header').outerHeight() - $(element).outerHeight()) + 'px'
                // });
            });
    
            new Waypoint({
                element: '#company-index',
                handler: direction => {
                    if(direction === 'down') {
                        $('.nav-history').addClass('is-hide');
                    }
                },
                offset: '75%'
            });
    
            new Waypoint({
                element: '#company-index',
                handler: direction => {
                    if(direction === 'up') {
                        $('.nav-history').removeClass('is-hide');
                    }
                },
                offset: '75%'
            });
        }

        $(window).on('resize', () => {
            setTimeout(() => {
                refresh();
            }, 100);
        });
    };

    /**
     * refresh
     */
    const refresh = () => {
        Waypoint.refreshAll();
    };

    return {
        init: init,
        refresh: refresh
    };
})();
